<template>
  <div class="cards__container">
    <b-card class="card-nf nfe-emitidas" sub-title="NFS-e Emitidas">
      <p>
        <span>{{ dashboard?.cards.notes_issued || 0 }}</span> de
        <span class="text-primary">
          {{ dashboard?.contract.notes_per_period || 0 }}
        </span>
        notas
      </p>
    </b-card>

    <b-card class="card-nf nfe-excedente" sub-title="NFS-e Excedentes">
      <div class="tooltip__container">
        <span id="nfe-excedente" class="icon-box">
          <v-help-circle class="icon stroke" />
        </span>
        <b-tooltip target="nfe-excedente" placement="bottom">
          A cada nota excedente será cobrado um valor de
          {{ parseNumberToMoney(dashboard?.contract.exceed_note_value) || 0 }}
        </b-tooltip>
      </div>

      <p>
        <span> + {{ dashboard?.cards.exceeded_notes || 0 }} NFS-e</span>
        / +
        {{
          parseNumberToMoney(
            dashboard?.cards.exceeded_notes *
              dashboard?.contract.exceed_note_value
          ) || 0
        }}
      </p>
    </b-card>

    <b-card class="card-nf nfe-canceladas" sub-title="NFS-e Canceladas">
      <p>{{ dashboard?.cards.canceled_notes || 0 }}</p>
    </b-card>

    <b-card class="card-nf nfe-total" sub-title="Total do período">
      <p>
        {{ parseNumberToMoney(dashboard?.cards.total_amount_notes) || 0 }}
      </p>
    </b-card>
  </div>
</template>

<script>
import { parseNumberToMoney } from '@/utils/moneyHelper'

export default {
  props: {
    dashboard: Object
  },
  components: {
    'v-help-circle': () => import('@/assets/icons/help-circle.svg')
  },
  data() {
    return {}
  },
  methods: {
    parseNumberToMoney
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/main';

.cards__container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  max-height: 100px;
  column-gap: 1rem;

  .card-nf {
    width: 100%;
    max-height: 80px;
    border-radius: 8px;
    box-shadow: 0px 2px 1px 0px #0c1d590a;

    .card-body {
      padding: 1rem;
    }

    h6 {
      font-size: 16px;
      font-weight: 700;
      font-family: 'Nunito Sans';
      color: var(--dark-blue) !important;
    }

    p {
      margin: 0;
      font-weight: normal;
      color: var(--neutral-500);
      > span {
        font-size: 18px;
        font-weight: 700;
      }
    }

    &.nfe-emitidas {
      grid-area: 'nfe-emitidas';
      p {
        span {
          color: var(--blue-500);
        }
      }
    }

    &.nfe-excedente {
      position: relative;
      grid-area: 'nfe-excedente';

      .tooltip__container {
        width: 18px;
        height: 18px;
        position: absolute;
        right: 8px;
        top: 4px;
      }

      p {
        color: var(--type-active);
        font-size: 14px;
        span {
          font-size: 18px;
        }
      }
    }

    &.nfe-canceladas {
      grid-area: 'nfe-canceladas';
      p {
        font-weight: 700;
        color: var(--states-error);
      }
    }

    &.nfe-total {
      grid-area: 'nfe-total';

      p {
        font-size: 18px;
        font-weight: 700;
        color: var(--states-success-dark-green);
      }
    }
  }
}
</style>
